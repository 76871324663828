import unionWith from 'lodash/unionWith';
import config from '../../config';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { storableError } from '../../util/errors';

import * as log from '../../util/log';

import { MeiliSearch } from 'meilisearch';
// import movies from './movies.json'
import _ from 'lodash';
import { logEvent } from '../../util/logsEvent';

const client = new MeiliSearch({
  host: process.env.REACT_APP_MEILISEARCH_HOST,
  apiKey: process.env.REACT_APP_MEILISEARCH_SEARCH_KEY,
});

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_SUCCESS_APPEND = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS_APPEND';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_LIST_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_LIST_SET_ACTIVE_LISTING';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  appendInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
  likeInProgress: null,
  likeError: null,
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        appendInProgress: !!payload.searchParams.append,
        currentPageResultIds: payload.searchParams.append ? state.currentPageResultIds : [],
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS: {
      const results = resultIds(payload.data);
      const { keywords, track, pub_category, collectionName } = state.searchParams;

      if (collectionName) {
        logEvent('collection viewed', {
          ['collection name']: collectionName.replaceAll("_"," ") ,
          ['number of search results']: results.length,
        });
      }
      if (track || pub_category) {
        logEvent('collection viewed', {
          ['collection name']: pub_category || track,
          ['number of search results']: results.length,
        });
      }
      if (keywords?.length > 3) {
        logEvent('search completed', { keywords, ['number of search results']: results.length });
      }
      return {
        ...state,
        currentPageResultIds: results,
        pagination: payload.data.meta,
        searchInProgress: false,
        appendInProgress: false,
      };
    }
    case SEARCH_LISTINGS_SUCCESS_APPEND:
      return {
        ...state,
        currentPageResultIds: state.currentPageResultIds.concat(resultIds(payload.data)),
        pagination: payload.data.meta,
        searchInProgress: false,
        appendInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      return {
        ...state,
        searchInProgress: false,
        appendInProgress: false,
        searchListingsError: payload,
      };

    case SEARCH_MAP_LISTINGS_REQUEST:
      return {
        ...state,
        searchMapListingsError: null,
      };
    case SEARCH_MAP_LISTINGS_SUCCESS: {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(payload.data),
        (id1, id2) => id1.uuid === id2.uuid
      );
      return {
        ...state,
        searchMapListingIds,
      };
    }
    case SEARCH_MAP_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      return { ...state, searchMapListingsError: payload };

    case SEARCH_LIST_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsSuccessAppend = response => ({
  type: SEARCH_LISTINGS_SUCCESS_APPEND,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_LISTINGS_REQUEST });

export const searchMapListingsSuccess = response => ({
  type: SEARCH_MAP_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchMapListingsError = e => ({
  type: SEARCH_MAP_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchListings = searchParams => async (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [inSubunits(values[0]), inSubunits(values[1])],
        }
      : {};
  };

  const searchParamToArray = searchParams => {
    return searchParams ? searchParams.split(',') : [];
  };

  const {
    perPage,
    append,
    price,
    dates,
    pub_brand,
    pub_category,
    pub_size,
    pub_color,
    pub_use,
    pub_style,
    page,
    sort,
    featured,
    hotlist,
    coleccion,
    ...rest
  } = searchParams;
  const priceMaybe = priceSearchParams(price);
  //meilisearch query
  let meilisearchQueryIdsMaybe;

  const queryString = searchParams.keywords ? searchParams.keywords : '';
  const lowerPrice = price ? priceMaybe.price[0] : undefined;
  const upperPrice = price ? priceMaybe.price[1] : undefined;
  let brand = searchParamToArray(pub_brand);
  let category = searchParamToArray(pub_category);
  let color = searchParamToArray(pub_color);
  let size = searchParamToArray(pub_size);
  let style = searchParamToArray(pub_style);
  let use = searchParamToArray(pub_use);
  let pub_coleccion = searchParamToArray(coleccion);

  let filterArray = [];

  let featuredVar = featured !== undefined ? `featured${featured ? "=" : "!="}'true'` : null;
  let hotlistVar = hotlist !== undefined ? `hotlist${hotlist ? "=" : "!="}'true'` : null;

  pub_coleccion = pub_coleccion.map(col => {
    return `coleccion = '${col}'`;
  });

  brand = brand.map(brand => {
    return `brand = '${brand}'`;
  });

  category = category.map(category => {
    return `category ='${category}'`;
  });

  color = color.map(color => {
    return `color ='${color}'`;
  });

  size = size.map(size => {
    return `size = '${size}'`;
  });

  style = style.map(style => {
    return `style = '${style}'`;
  });

  use = use.map(use => {
    return `use = '${use}'`;
  });

  let filter =
    lowerPrice !== undefined
      ? filterArray.push(`(${`price > ${lowerPrice} AND price < ${upperPrice}`})`)
      : undefined;
  filter = brand.length > 0 ? filterArray.push(`(${brand.join(' OR ')})`) : undefined;
  filter = category.length > 0 ? filterArray.push(`(${category.join(' OR ')})`) : undefined;
  filter = color.length > 0 ? filterArray.push(`(${color.join(' OR ')})`) : undefined;
  filter = size.length > 0 ? filterArray.push(`(${size.join(' OR ')})`) : undefined;
  filter = style.length > 0 ? filterArray.push(`(${style.join(' OR ')})`) : undefined;
  filter = use.length > 0 ? filterArray.push(`(${use.join(' OR ')})`) : undefined;
  filter = pub_coleccion.length > 0 ? filterArray.push(`(${pub_coleccion.join(' OR ')})`) : undefined;

    if (featuredVar && hotlistVar) {
      filterArray.push(`(${featuredVar} AND ${hotlistVar})`);
    } else if (featuredVar) {
      filterArray.push(`(${featuredVar})`);
    } else if (hotlistVar) {
      filterArray.push(`(${hotlistVar})`);
    }

  filter = filterArray.join(' AND ');

  let sortObject = [];

  switch (sort) {
    case '-createdAt':
      sortObject.push('date_timestamp:asc');
      break;
    case 'createdAt': // Desc
      sortObject.push('date_timestamp:desc');
      break;
    case '-price':
      sortObject.push('price:asc');
      break;
    case 'price': // Desc
      sortObject.push('price:desc');
      break;
    case '-pub_updatedAt':
      sortObject.push('update_date_timestamp:desc');
      break;
    default:
      sortObject.push('date_timestamp:desc');
      break;
  }

  //query obtener usuarios featured, modificando para agregar a usuario


  // only query string
  client
    .index(process.env.REACT_APP_MEILISEARCH_DB_NAME)
    .search(queryString, {
      filter: filter,
      limit: 50,
      offset: 50 * (page - 1),
      sort: sortObject,
      matchingStrategy: 'all',
    })
    .then(({ hits, estimatedTotalHits }) => {
      const returnedQueryIds = hits.map(hit => {
        return hit.id;
      });

      meilisearchQueryIdsMaybe = { ids: returnedQueryIds || [] };
      const params = {
        ...rest,
        ...meilisearchQueryIdsMaybe,
        pub_sold: false,
        per_page: perPage,
        sort: sort,
      };

      delete params.keywords;
      delete params.sort;

      return sdk.listings
        .query(params)
        .then(response => {
          dispatch(addMarketplaceEntities(response));
          //when searching sort the returned data from sharetribe as they are returned on meilisearch
          const meilisearchIdOrder = new Map(meilisearchQueryIdsMaybe.ids.map((t, i) => [t, i]));
          response.data.data = _.sortBy(response.data.data, r => meilisearchIdOrder.get(r.id.uuid));
          response.data.meta.totalItems = estimatedTotalHits || 0;
          response.data.meta.totalPages = Math.ceil(estimatedTotalHits / 50) || 0;
          response.data.meta.page = page;
          if (append) {
            dispatch(searchListingsSuccessAppend(response));
          } else {
            dispatch(searchListingsSuccess(response));
          }
          return response;
        })
        .catch(e => {
          const data = {
            error_description: 'Search page error, error fetching listings through flex',
          }
          log.error(e, 'fetching-listings-failed', data, 'listing')
          dispatch(searchListingsError(storableError(e)));
          throw e;
        });
    })
    .catch(e => {

      const data = {
        error_description: 'Search page error, error fetching listings through melisearch',
      }
      log.error(e, 'fetching-listings-failed', data, 'listing')
    });
};

export const setActiveListing = listingId => ({
  type: SEARCH_LIST_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchMapListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchMapListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchMapListingsError(storableError(e)));
      throw e;
    });
};
